
@font-face{
  font-family: Poppins;
  src: local('Poppins'),
        url(./Components/fonts/Poppins-Regular.ttf) format(trueType)
}


.App {
  background-color: var(--bg-color);
  font-family: 'Poppins', sans-serif;
}
.page-content {
  display: flex;
  justify-content: flex-end;
  /* color: white; */
  position: absolute;
  right: 0;
  /* height: 80%; */
  width: auto;
  /* border: 1px solid green; */
}

.page-section {
  width: 100%;
}

.main-section {
  display: flex;
  margin-top: 100px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
